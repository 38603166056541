
// BTNs auxiliares PDP Graduação
$(window).on('load', function () {

  if ($(window).width() <= 992) {
    $('.js-btn-duracaocurso').on('click', function (e) {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      setTimeout(function () {
        $('.scs-cardinfo-resumo-duracao').closest('a').addClass('js-btn-duracaocurso-ativo');
      }, 1000);
      setTimeout(function () {
        $('.scs-cardinfo-resumo-duracao').closest('a').removeClass('js-btn-duracaocurso-ativo');
      }, 2000);
    });
  }

  if ($(window).width() >= 992) {
    $('.js-btn-duracaocurso').on('click', function (e) {
      $('.scs-cardinfo-resumo-duracao').closest('a').addClass('js-btn-duracaocurso-ativo');
      setTimeout(function () {
        $('.scs-cardinfo-resumo-duracao').closest('a').removeClass('js-btn-duracaocurso-ativo');
      }, 2000);
    });
  }

});

document.querySelectorAll('a.js-btn-investimento, a.js-btn-duracaocurso').forEach(botao => {
  botao.addEventListener('click', () => {
    let sidebar = (window.innerWidth > 992) ? '.sobre-curso-sidebar' : '.card-mobile';

    $('html').animate({
      scrollTop: (document.querySelector(`${sidebar}`).offsetTop + (sidebar === '.sobre-curso-sidebar' ? 200 : 0))
    }, "fast");

    setTimeout(() => {
      document.querySelector(`${sidebar}`).classList.toggle('chamando-atencao');
    }, 1000);

    setTimeout(() => {
      document.querySelector(`${sidebar}`).classList.toggle('chamando-atencao');
    }, 3000);
  });
})

$(window).on('load', function () {

  if ($(window).width() <= 992) {
    $('.js-btn-investimento').on('click', function (e) {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      setTimeout(function () {
        $('.btnconhecamobile').addClass('js-btn-investimento-ativo');
      }, 1000);
      setTimeout(function () {
        $('.btnconhecamobile').removeClass('js-btn-investimento-ativo');
      }, 2000);
    });
  }

  if ($(window).width() >= 992) {
    $('.js-btn-investimento').on('click', function (e) {
      $('.btnconheca').addClass('js-btn-investimento-ativo');
      setTimeout(function () {
        $('.btnconheca').removeClass('js-btn-investimento-ativo');
      }, 2000);
    });
  }

});
// END BTNs auxiliares PDP Graduação
